import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';
import { addDays, parse } from "date-fns";
import ReactLoading from 'react-loading';
import config from "../../config"
import queryString from 'query-string';

const Invoice_vendor = () => {
  const location = useLocation()
  let navigate = useNavigate()
  
  let  parsed = queryString.parse(location.search);
    // const shipment_id = useParams()
    // console.log("shipment_id",shipment_id.shipment_id)
    const gstRate = 18; // GST rate in percentage

// Calculate GST amount
const gstAmount = (parsed.amount * gstRate) / 100;

// Calculate remaining amount after GST
const remainingAmount = parsed.amount - gstAmount;

let date15DaysAfter =""
    let userDetail = localStorage.getItem('ship_rocket_user');
    console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    const[state,setState]= React.useState()
    const[statecustomer,setStatecustomer]= React.useState()
    const[stateshipment,setStateshipment]= React.useState()

    const[isLoading,setisLoading]= React.useState(true)

    console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})

    React.useEffect(() => {
  
        shipment_list()
        dimension_detail()
    
      }, [])

    const shipment_list=()=>{
        let dataToSend = {customer_id : userData.customer_id
    };
   
      console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/invoice_details';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            console.log("responseJson => ", res);
            if(res.data.status == true){
            setState(res.data.output)
            setStatecustomer(res.data.customer)
           
            setisLoading(false)
          
            }
  
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              console.log(error);
          });    

    }
    const SenderAddress = () =>{
      if(state.shipment_list[0].items.length > 0){
        return state.shipment_list[0].items.reduce((previousValue, currentValue) =>{
          return previousValue.item_weight + currentValue.item_weight}) 
      }else {
        return 0
      }
     } 


     const dimension_detail = () =>{
      let full_api = config.apiUrl + `/user/get_company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
    const calculateDueDate = (initialDate, daysToAdd) => {
      const initialDateObject = new Date(initialDate);
      const dueDateObject = new Date(initialDateObject);
      dueDateObject.setDate(initialDateObject.getDate() + daysToAdd);
  
      // Format the due date to YYYY/MM/DD
      const formattedDueDate = `${dueDateObject.getFullYear()}-${
        dueDateObject.getMonth() + 1
      }-${dueDateObject.getDate()}`;
  
      return formattedDueDate;
    };
   
  return (
    <div>
       {isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>
  :
      <div class="container mt-3 wrap">
    <div class="card mb-1">
        <div class="card-body">
            <div class="pb-5">
                <img src="../../../img/logo_5.png" height="70px" />
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="pb-4">
                    <strong>{state.invoice_name}</strong><br/>
                       {state.invoice_address}<br/>
                        Phone: {state.invoice_phone}<br/>
                        Email: {state.invoice_email}
                    </div>
                    <div>
                    <strong>PAN Number:</strong> {state.invoice_pan}<br/>
                        <strong>CIN Number:</strong>  {state.invoice_cin}<br/>
                        <strong>GSTIN:</strong>  {state.invoice_gst}<br/>
                        <strong>IRN:</strong>  {state.invoice_irn}
                    </div>
                </div>
                <div class="col-5">
                    <h6 class="pb-3"><strong>TAX INVOICE</strong></h6>
                    <h2 class="text-success">PAID</h2>
                    <div class="download_btn">
                        <a href="javascript:window.print();">Download Invoice</a>
                    </div>
                    <div class="pt-2">
                        <strong>Invoice No. :</strong> {parsed.invoice_id}<br/>
                        <strong>Invoice Date :</strong> {parsed.invoice_date}<br/>
                        <strong>Due Date :</strong> {calculateDueDate(parsed.invoice_date
                          , 15)}<br/>
                            <strong>Invoice Period : </strong> 
                            {(() => {
  const getStartAndEndDates = (monthYear) => {
    const [monthName, year] = monthYear.split(","); // Split month and year
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth(); // Get zero-based month index
    const startDate = new Date(year, monthIndex, 1); // First day of the month
    const endDate = new Date(year, monthIndex + 1, 0); // Last day of the month

    // Format date as YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
      const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
      return `${year}-${month}-${day}`;
    };

    return `${formatDate(startDate)} to ${formatDate(endDate)}`; // Format range
  };

  return getStartAndEndDates(parsed.invoice_period); // Render period as range
})()}
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-7">
                <strong>Invoice To:</strong>
                {statecustomer.company_name?statecustomer.company_name:statecustomer.full_name?statecustomer.full_name:""} <br/>
                    {statecustomer.address} <br/>
                    {statecustomer.city}
                </div>
                <div class="col-5">
                    <strong>GSTIN:</strong> {statecustomer.gst_number?statecustomer.gst_number:""} <br/>
                </div>
            </div>
        </div>
    </div> 

    <div class="card mb-1">
      <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SAC No.</th>
                    <th>Description</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>996812</td>
                      <td>Shipdartexpress V2 Freight*</td>
                      <td>{remainingAmount}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>18.00% IGST</td>
                      <td>{gstAmount}</td>
                    </tr>
                  <tr>
                    <td></td>
                    <td class="fw-bold">Grand Total Value</td>
                    <td>{parsed.amount}</td>
                  </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
    <div class="card mb-1">
        <div class="card-body">
          <div class="table-responsive">
              <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Transaction Date</th>
                      <th>Gateway</th>
                      <th>Transaction ID</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>{parsed.invoice_date}</td>
                        <td>Wallet</td>
                        <td>NA</td>
                        <td>{parsed.amount}</td>
                      </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td class="fw-bold">Amount Due</td>
                      <td>0.00</td>
                    </tr>
                  </tbody>
              </table>
          </div>
        </div>
    </div>
    <div class="card mb-1">
        <div class="card-body">
            <div class="border-bottom py-2">
                <strong>Bank and Other Commercial Details</strong>
            </div>
            <div>
                All Payments by transfer/check/DD should be draw in favour of <br/>
                <strong>Entity Name:</strong> {state.entity_name} <br/>
                <strong>Account number:</strong> {state.account_number} <br/>
                <strong>Bank:</strong> {state.bank} <br/>
                <strong>Branch:</strong> {state.branch} <br/>
                <strong>RTGS/NEFT/IFSC Code:</strong> {state.ifsc_code}
            </div>
        </div>
    </div>
</div>
}
    </div>
  )
}

export default Invoice_vendor
